<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small" @submit.native.prevent>
				<el-form-item label="名称" label-width="50px" prop="keyword" >
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" @keyup.enter.native="query"/>
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<!-- <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button> -->
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ scope.row.id }}
				  </template>
				</el-table-column>
				<el-table-column label="状态" min-width="80" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.status === 1" size="mini" type="success">已审核</el-tag>
					<el-tag v-if="scope.row.status === 2" size="mini" type="warning">待审核</el-tag>
					<el-tag v-if="scope.row.status === 3" size="mini" type="warning">管理员审核</el-tag>
					<el-tag v-if="scope.row.status === 4" size="mini" type="danger">驳回</el-tag>
				  </template>
				</el-table-column>
				<el-table-column label="名称" min-width="200" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.name }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="联系人" min-width="110" align="center">
				  <template slot-scope="scope">
					{{ scope.row.work_person }}
				  </template>
				</el-table-column>
				<el-table-column label="电话" min-width="110" align="center">
				  <template slot-scope="scope">
					{{ scope.row.phone }}
				  </template>
				</el-table-column>
				<el-table-column label="申请表" min-width="110" align="center">
				  <template slot-scope="scope">
					<div style="color: #2d8cf0;cursor: pointer;" @click="handleViewFile(scope.row.word)">查看</div>
				  </template>
				</el-table-column>
				<el-table-column label="pdf" min-width="110" align="center">
				  <template slot-scope="scope">
					<div style="color: #2d8cf0;cursor: pointer;" @click="handleDownload(scope.row.pdf)">查看</div>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="170">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.utime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="驳回意见" min-width="110" align="center">
				  <template slot-scope="scope">
					{{ scope.row.remarks }}
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center" fixed="right">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="审核" placement="top" v-if="scope.row.status == 2 || scope.row.status == 3">
					  <el-button type="warning" icon="el-icon-setting" size="mini" circle @click="handleOperate(scope.row)"></el-button>		
		            </el-tooltip>
					<el-tooltip class="item" effect="dark" content="删除" placement="top">
					  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
		<!-- 审核-->
		<el-dialog :visible.sync="operate" width="800px" :close-on-click-modal="false" append-to-body :title="title">
			<el-form ref="operate" :model="operateForm" label-width="120px" size="small" :rules="rules">
			    <el-form-item  label="审核状态" prop="status">
					<el-radio-group v-model="operateForm.status">
					   <el-radio :label="1">通过</el-radio>
					   <el-radio :label="4">驳回</el-radio>
					 </el-radio-group>
			    </el-form-item>
				<el-form-item  label="驳回原因" prop="remarks" v-if="operateForm.status == 4">
				    <el-input v-model="operateForm.remarks" placeholder="请输入驳回原因" type="textarea" :rows="3" clearable />
				</el-form-item>
				<el-row v-if="operateForm.status == 1">
					<el-col :span="12">
						<el-form-item  label="名称" prop="name" >
						    <el-input v-model="operateForm.name" placeholder="请输入名称" type="text" clearable />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item  label="信用代码" prop="xy_code">
						    <el-input v-model="operateForm.xy_code" placeholder="请输入统一社会信用代码" type="text" clearable />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="operateForm.status == 1">
					<el-col :span="12">
						<el-form-item  label="法人名称" prop="legal_person" >
						    <el-input v-model="operateForm.legal_person" placeholder="请输入法人名称" type="text" clearable />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item  label="电话" prop="l_phone">
						    <el-input v-model="operateForm.l_phone" placeholder="请输入电话" type="text" clearable />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="operateForm.status == 1">
					<el-col :span="12">
						<el-form-item  label="手机" prop="l_mobile" >
						    <el-input v-model="operateForm.l_mobile" placeholder="请输入手机" type="text" clearable />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item  label="职务" prop="l_duties">
						    <el-input v-model="operateForm.l_duties" placeholder="请输入职务" type="text" clearable />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="operateForm.status == 1">
					<el-col :span="12">
						<el-form-item  label="传真" prop="l_fax" >
						    <el-input v-model="operateForm.l_fax" placeholder="请输入传真" type="text" clearable />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item  label="邮箱" prop="l_email">
						    <el-input v-model="operateForm.l_email" placeholder="请输入邮箱" type="text" clearable />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="operateForm.status == 1">
					<el-col :span="12">
						<el-form-item  label="联系人" prop="work_person" >
						    <el-input v-model="operateForm.work_person" placeholder="请输入联系人" type="text" clearable />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item  label="联系人电话" prop="work_phone">
						    <el-input v-model="operateForm.work_phone" placeholder="请输入联系人电话" type="text" clearable />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="operateForm.status == 1">
					<el-col :span="12">
						<el-form-item  label="联系人手机" prop="work_mobile" >
						    <el-input v-model="operateForm.work_mobile" placeholder="请输入联系人手机" type="text" clearable />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item  label="联系人职务" prop="work_duties">
						    <el-input v-model="operateForm.work_duties" placeholder="请输入联系人职务" type="text" clearable />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="operateForm.status == 1">
					<el-col :span="12">
						<el-form-item  label="联系人传真" prop="work_fax" >
						    <el-input v-model="operateForm.work_fax" placeholder="请输入联系人传真" type="text" clearable />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item  label="联系人邮箱" prop="work_email">
						    <el-input v-model="operateForm.work_email" placeholder="请输入联系人邮箱" type="text" clearable />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="operateForm.status == 1">
					<el-col :span="12">
						<el-form-item  label="单位地址" prop="address" >
						    <el-input v-model="operateForm.address" placeholder="请输入单位地址" type="text" clearable />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item  label="注册资金" prop="reg_money">
						    <el-input v-model="operateForm.reg_money" placeholder="请输入注册资金(万)" type="text" clearable />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="operateForm.status == 1">
					<el-col :span="12">
						<el-form-item  label="从业人数" prop="people_num" >
						    <el-input v-model="operateForm.people_num" placeholder="请输入从业人数(人)" type="text" clearable />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item  label="产值" prop="cz">
						    <el-input v-model="operateForm.cz" placeholder="请输入产值(万)" type="text" clearable />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="operateForm.status == 1">
					<el-col :span="12">
						<el-form-item  label="纳税额" prop="nse" >
						    <el-input v-model="operateForm.nse" placeholder="请输入纳税额(万)" type="text" clearable />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item  label="单位性质" prop="dw_xz">
						    <el-input v-model="operateForm.dw_xz" placeholder="请输入单位性质" type="text" clearable />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="operateForm.status == 1">
					<el-col :span="12">
						<el-form-item  label="许可证编号" prop="cz_code" >
						    <el-input v-model="operateForm.cz_code" placeholder="请输入许可证编号" type="text" clearable />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item  label="承装等级" prop="cz_grade">
						    <el-input v-model="operateForm.cz_grade" placeholder="请输入承装等级" type="text" clearable />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="operateForm.status == 1">
					<el-col :span="12">
						<el-form-item  label="承修等级" prop="cx_grade" >
						    <el-input v-model="operateForm.cx_grade" placeholder="请输入承修等级" type="text" clearable />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item  label="承试等级" prop="cs_grade">
						    <el-input v-model="operateForm.cs_grade" placeholder="请输入承试等级" type="text" clearable />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="operateForm.status == 1">
					<el-col :span="12">
						<el-form-item  label="许可证有效期" prop="xkz_time" >
						    <el-input v-model="operateForm.xkz_time" placeholder="请输入许可证有效期" type="text" clearable />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item  label="其他电力资质" prop="other_aptitude">
						    <el-input v-model="operateForm.other_aptitude" placeholder="请输入其他电力相关资质" type="text" clearable />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="operateForm.status == 1">
					<el-col :span="12">
					  <el-form-item label="申请类型" prop="grade">
						 <el-select v-model="operateForm.grade" placeholder="请选择申请类型" size="medium" class="w-100">
							 <el-option label="未选择" :value="0"></el-option>
							   <el-option
								 v-for="item in grades"
								 :key="item.id"
								 :label="item | tree"
								 :value="item.id"
							   />
						 </el-select>
					  </el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="operateForm.status == 1">
					<el-col :span="12">
						<el-form-item  label="备注" prop="desc" >
						    <el-input v-model="operateForm.desc" placeholder="请输入备注" type="textarea" :rows="3" clearable />
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
			  <el-button size="mini" @click="operateCancel">取 消</el-button>
			  <el-button type="primary" size="mini" @click="handleOperateSubmit">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 审核-->
		<el-dialog :visible.sync="office" width="800px" :close-on-click-modal="false" append-to-body :title="title">
			<iframe :src="file_url" frameborder="0" width="100%" height="600"></iframe>
			<div slot="footer" class="dialog-footer">
			  <el-button size="mini" @click="officeCancel">取 消</el-button>
			  <el-button type="primary" size="mini" @click="officeCancel">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips
		},
		data() {
			return {
				preUrl: 'apply',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				rules:{
					status: [
					  { required: true, message: '请选择审核状态', trigger: 'blur' }
					],
				},
				operate:false,
				operates:[
					{id:1,name:'通过'},
					{id:3,name:'驳回'},
				],
				operateForm:{
					id:'',
					status:'',
					remarks:'',
					name:'',
					xy_code:'',
					legal_person:'',
					l_phone:'',
					l_mobile:'',
					l_duties:'',
					l_fax:'',
					l_email:'',
					work_person:'',
					work_phone:'',
					work_mobile:'',
					work_duties:'',
					work_fax:'',
					work_email:'',
					address:'',
					reg_money:'',
					people_num:'',
					cz:'',
					nse:'',
					dw_xz:'',
					cz_code:'',
					cz_grade:'',
					cx_grade:'',
					cs_grade:'',
					xkz_time:'',
					other_aptitude:'',
					desc:'',
					grade:0,
					role:0,
				},
				office:false,
				file_url:"",
				apply_data:{},
				grades:[
					// {id:1,name:'安徽省电力协会会员'},
					// {id:7,name:'安徽省电力协会理事'},
					// {id:3,name:'发电分会'},
					// {id:4,name:'新能源与储能分会'},
					// {id:5,name:'售电与综合能源分会'},
					// {id:8,name:'会员'},
					// {id:9,name:'副会长'},
				],
				roles:[],
			}
		},
		mounted() {
			this.getApplayGrades()
			this.getUserRoles()
		},
		methods:{
			applyData(){
				this.axios.get('/manage/apply/apply_data',{
					params:{
						id:this.operateForm.id
					}
				}).then(res=>{
					if(res.status){
						this.apply_data = res.data
						this.operateForm.name = res.data.name
						this.operateForm.xy_code=res.data.xy_code
						this.operateForm.legal_person=res.data.legal_person
						this.operateForm.l_phone=res.data.l_phone
						this.operateForm.l_mobile=res.data.l_mobile
						this.operateForm.l_duties=res.data.l_duties
						this.operateForm.l_fax=res.data.l_fax
						this.operateForm.l_email=res.data.l_email
						this.operateForm.work_person=res.data.work_person
						this.operateForm.work_phone=res.data.work_phone
						this.operateForm.work_mobile=res.data.work_mobile
						this.operateForm.work_duties=res.data.work_duties
						this.operateForm.work_fax=res.data.work_fax
						this.operateForm.work_email=res.data.work_email
						this.operateForm.address=res.data.address
						this.operateForm.reg_money=res.data.reg_money
						this.operateForm.people_num=res.data.people_num
						this.operateForm.cz=res.data.cz
						this.operateForm.nse=res.data.nse
						this.operateForm.dw_xz=res.data.dw_xz
						this.operateForm.cz_code=res.data.cz_code
						this.operateForm.cz_grade=res.data.cz_grade
						this.operateForm.cx_grade=res.data.cx_grade
						this.operateForm.cs_grade=res.data.cs_grade
						this.operateForm.xkz_time=res.data.xkz_time
						this.operateForm.other_aptitude=res.data.other_aptitude
						this.operateForm.desc=res.data.desc
						this.operateForm.grade=res.data.grade
						this.operateForm.role=res.data.role
					}
				})
			},
			reset() {
			  this.operateForm = {
			    id:'',
			   	status:'',
			   	remarks:'',
			   	name:'',
			   	xy_code:'',
			   	legal_person:'',
			   	l_phone:'',
			   	l_mobile:'',
			   	l_duties:'',
			   	l_fax:'',
			   	l_email:'',
			   	work_person:'',
			   	work_phone:'',
			   	work_mobile:'',
			   	work_duties:'',
			   	work_fax:'',
			   	work_email:'',
			   	address:'',
			   	reg_money:'',
			   	people_num:'',
			   	cz:'',
			   	nse:'',
			   	dw_xz:'',
			   	cz_code:'',
			   	cz_grade:'',
			   	cx_grade:'',
			   	cs_grade:'',
			   	xkz_time:'',
			   	other_aptitude:'',
			   	desc:'',
				grade:0,
				role:0,
			  }
			  this.resetForm('form')
			},
			handleOperate(row){
				this.reset()
				this.operate = true
				this.title = '审核'
				this.operateForm.id = row.id
				this.$nextTick(()=>{
					this.applyData()
				})
			},
			operateCancel(){
				this.operate = false
			},
			handleOperateSubmit(){
				this.$refs.operate.validate(valid => {
				  if (valid) {
					  if(this.operateForm.status == 1){
						  if(this.operateForm.grade == 0){
							  this.$message.error('请选择申请类型')
							  return false
						  }
					  }
					  this.axios.post('/manage/apply/operate', this.operateForm).then(res => {
					    if (res.status) {
							this.$message.success('审核成功')
							this.operate = false
							this.getList()
							this.reset()
					    } else {
					  	    this.$message.error(res.msg)
					    }
					  })
				  }
				})
			},
			officeCancel(){
				this.office = false
			},
			handleViewFile(url){
				this.office = true
				this.file_url = 'https://view.officeapps.live.com/op/view.aspx?src='+encodeURIComponent('https://api.mem.ahpea.cn/'+url)
			},
			handleDownload(url){
				this.office = true
				this.file_url = 'https://api.mem.ahpea.cn/'+url
			},
			getApplayGrades(){
				// this.axios.get('/manage/user_grade/all').then(res=>{
				//   if(res.status){
				// 	let grades = res.data
				// 	var index = grades.findIndex((v)=>v.id == 2)
				// 	grades.splice(index,1)
				// 	var index = grades.findIndex((v)=>v.id == 99)
				// 	grades.splice(index,1)
				// 	this.grades = grades
				//   }
				// })
				this.axios.get('/manage/user_grade/level').then(res=>{
				  if(res.status){
					let grades = res.data
					this.grades = grades
				  }
				})
			},
			getUserRoles(){
				this.axios.get('/manage/user_role/all').then(res=>{
				  if(res.status){
					this.roles = res.data
				  }
				})
			},
		},
		filters: {
		    tree(item) {
		        if (item.level == 0) {
		          return item.name
		        }
		        let str = ''
		        for (let i = 0; i < item.level; i++) {
		          str += i == 0 ? '|--' : '--'
		        }
		        return str + ' ' +item.name;
		    }
		},
	}
</script>

<style>
</style>